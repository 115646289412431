import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`

    * {
        box-sizing: border-box;
    }

    body {
        font-family: "Bebas Neue", Arial, Helvetica, sans-serif;
        font-size: 16px;
        margin: 0;
        width: 100%;
    }

    p {
        line-height: 2em;
    }
`;

export default GlobalStyles;
