import React from "react";
import {
  StyledFooterSectionsHeader,
  FooterSection,
  StyledFlexCol,
  FooterNavigationLink
} from "../../styles/helpers.styled";
import { Link } from "react-router-dom";

function Navigation() {
  return (
    <FooterSection>
      <StyledFooterSectionsHeader>Navigation</StyledFooterSectionsHeader>
      <StyledFlexCol>
        <FooterNavigationLink href="#">Home</FooterNavigationLink>
        <FooterNavigationLink href="#about">About</FooterNavigationLink>
        <FooterNavigationLink href="#features">Features</FooterNavigationLink>
        <FooterNavigationLink href="#videos">Video</FooterNavigationLink>
        <FooterNavigationLink>
          <Link to={"policy"} style={{color: "rgba(255, 255, 255, 0.7)", textDecoration: "none"}}>Privacy Policy</Link>
        </FooterNavigationLink>
      </StyledFlexCol>
    </FooterSection>
  );
}

export default Navigation;
