import React from "react";
import {
  StyledAbout,
  StyledAboutSections,
  StyledFlexRow,
  StyledFootnote
} from "../styles/helpers.styled";

import { Svg1, Svg2, Svg3, Svg4 } from "../../data/data";

import { aboutData } from "../../data/data";

function About() {
  return (
    <StyledAbout id="about">
      <p style={{ fontSize: "0.875em", color: "#34dca2" }} className="to-animate">WELCOME</p>
      <h2
        style={{
          fontSize: "3em",
          color: "#fff",
          margin: "0",
          textAlign: "center",
        }}
        className="to-animate"
      >
        INTRODUCING BE$TOW
      </h2>
      <p
        style={{
          fontFamily: "Roboto",
          color: "#fff",
          fontSize: "1.3em",
          textAlign: "center",
        }}
      >
        Our trademarked and patent-pending service is a peer-to-peer mobile
        payment solution for small dollar amounts.<StyledFootnote name="footnote-1" href="#footnote-1">[1]</StyledFootnote>
      </p>
      <StyledAboutSections className="to-animate">
        <SectionStuff1 data={aboutData[0]} className="to-animate" />
        <SectionStuff2 data={aboutData[1]} className="to-animate" />
        <SectionStuff3 data={aboutData[2]} className="to-animate" />
        <SectionStuff4 data={aboutData[3]} className="to-animate" />
      </StyledAboutSections>
    </StyledAbout>
  );
}

function SectionStuff1({ data }) {
  return (
    <StyledFlexRow
      style={{
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Svg1 className="to-animate"/>
      <h3 style={{ fontSize: "2em", margin: "0", color: "#fff" }}>
      </h3>
      <p
        style={{
          textAlign: "center",
          margin: "0",
          color: "#fff",
          fontFamily: "Roboto",
          textTransform: "none",
          fontSize: "16px",
        }}
      >
        {data.text}<StyledFootnote name='footnote-3' href='#footnote-3'>[2]</StyledFootnote>
      </p>
    </StyledFlexRow>
  );
}

function SectionStuff2({ data }) {
  return (
    <StyledFlexRow
      style={{
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Svg2 />
      <h3 style={{ fontSize: "2em", margin: "0", color: "#fff" }}>
      </h3>
      <p
        style={{
          textAlign: "center",
          margin: "0",
          color: "#fff",
          fontFamily: "Roboto",
          textTransform: "none",
          fontSize: "16px",
        }}
      >
        {data.text}
      </p>
    </StyledFlexRow>
  );
}

function SectionStuff3({ data }) {
  return (
    <StyledFlexRow
      style={{
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Svg3 />
      <h3 style={{ fontSize: "2em", margin: "0", color: "#fff" }}>
      </h3>
      <p
        style={{
          textAlign: "center",
          margin: "0",
          color: "#fff",
          fontFamily: "Roboto",
          textTransform: "none",
          fontSize: "16px",
        }}
      >
        {data.text}
      </p>
    </StyledFlexRow>
  );
}

function SectionStuff4({ data }) {
  return (
    <StyledFlexRow
      style={{
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Svg4 />
      <h3 style={{ fontSize: "2em", margin: "0", color: "#fff" }}>
      </h3>
      <p
        style={{
          textAlign: "center",
          margin: "0",
          color: "#fff",
          fontFamily: "Roboto",
          textTransform: "none",
          fontSize: "16px",
        }}
      >
        {data.text}
      </p>
    </StyledFlexRow>
  );
}

export default About;
