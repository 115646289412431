import React from "react";
import { StyledFooter, StyledWrapper } from "../../styles/helpers.styled";
import Contact from "./Contact";
import Footnotes from "./Footnotes";
import Navigation from "./Navigation";
import SocialMedias from "./SocialMedias";

import styled from "styled-components";

function Footer() {
  return (
    <StyledFooterWrapper>
      <StyledFooter>
        <Contact />
        <SocialMedias />
        <Navigation />
      </StyledFooter>
      <Footnotes />
    </StyledFooterWrapper>
  );
}

const StyledFooterWrapper = styled(StyledWrapper)`
  background-color: #222222;
  height: fit-content;
`;

export default Footer;
