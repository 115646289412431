import React from "react";
import {
  StyledEarlyAccess,
  StyledPlatformDownload,
  StyledPresentation,
  StyledPresentationInner,
  PresentationIframe,
  StyledDownloadButtons,
} from "../styles/helpers.styled";
import img from "../../assets/images/download-apple.png"

function Presentation() {
  const [changeUrl, setChangeUrl] = React.useState(false);

  React.useEffect(() => {
    if (changeUrl !== false)
      window.location.href =
        "https://apps.apple.com/us/app/bestow-early-access/id1586317338";
  }, [changeUrl]);

  const handleDownload = () => {
    setChangeUrl(true);
  };

  return (
    <StyledPresentation>
      <StyledPresentationInner>
        <PresentationIframe>
          <iframe
            title="main_video"
            src="https://www.youtube.com/embed/W0brNX2oEtQ?autoplay=1&amp;rel=0&amp;start&amp;end&amp;controls=1&amp;mute=1&amp;modestbranding=0&amp;autoplay=1"
            frameBorder="0"
            allowFullScreen="1"
            allow="autoplay;encrypted-media;"
            id="_dytid_4560"
            height="100%"
            width="100%"
            style={{ width: "100%", height: "100%" }}
          ></iframe>
        </PresentationIframe>
        <StyledEarlyAccess>
          <p style={{ margin: 0, color: "#34dca2", fontWeight: "200", letterSpacing: "1px" }}>
            CHECK OUT EARLY ACCESS
          </p>
          <h2
            style={{
              textAlign: "center",
              color: "#fff",
              fontSize: "3em",
              margin: "0",
              padding: "0"
            }}
          >
            BESTOW YOUR SOCIAL MEDIA, JUST <span style={{textDecoration: "underline rgb(52, 220, 162)"}}>TAP & GO</span>!
          </h2>
          <StyledPlatformDownload>
            <StyledDownloadButtons>
              <p style={{ color: "#fff", fontFamily: "Roboto" }}>iPhone 11 or higher:</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "202px",
                  height: "3.325em",
                  backgroungColor: "#fff",
                }}
                onClick={handleDownload}
              >
                <img
                  width="100%"
                  height="100%"
                  className="img-icon"
                  alt="null"
                  src={img}
                ></img>
              </div>
            </StyledDownloadButtons>
            <StyledDownloadButtons>
              <p style={{ color: "#fff", fontFamily: "Roboto" }}>Not on iOS?:</p>
              <button
                data-toggle="modal"
                data-target="#vl_popup"
                onClick="VL.openModal()"
                className="vl-reset-this vl-embedded-cta vrlps-trigger"
                value="Check your position"
                style={{
                  fontSize: "14px",
                  color: "rgb(0, 0, 0)",
                  backgroundColor: "rgb(52, 220, 162)",
                  fontFamily: "Roboto",
                  fontWeight: "bold",
                  textDecoration: "none",
                  borderRadius: "8px",
                  width: "160px",
                  height: "2.3em",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                Join The Waitlist
              </button>
            </StyledDownloadButtons>
          </StyledPlatformDownload>
        </StyledEarlyAccess>
      </StyledPresentationInner>
    </StyledPresentation>
  );
}

export default Presentation;
