import React from "react";
import {
  TextVideoSection,
  IframeContainer,
  TextContainer,
  StyledTextVideo,
} from "../../styles/helpers.styled";

function TextVideoComponent({ data }) {
  return (
    <StyledTextVideo backgroundColor={data.backgroundColor}>
    <TextVideoSection backgroundColor={data.backgroundColor} textOrder={data.textOrder} id="videos">
      <IframeContainer videoOrder={data.videoOrder} >
        <iframe
          title="main_video"
          src={data.video}
          frameBorder="0"
          allowFullScreen="1"
          allow="autoplay;encrypted-media;"
          id="_dytid_4560"
          style={{ width: "100%", height: "100%" }}
        ></iframe>
      </IframeContainer>

      <TextContainer textOrder={data.textOrder} className="to-animate" >
        <div style={{ color: "#34dca2", fontFamily: "Bebas Neue" }}>{data.subTitle}</div>
        <h2 style={{fontFamily: "Bebas Neue", fontSize: "2em", marginTop: "0.5em"}}>{data.title}</h2>
        <p style={{ color: "#767a7e", fontSize: "18px" }}>{data.text}</p>
      </TextContainer>
    </TextVideoSection>
    </StyledTextVideo>
  );
}

export default TextVideoComponent;