import React from 'react'
import styled from 'styled-components'
import {StyledFooter} from "../../styles/helpers.styled"

function Footnotes() {
  return (
    <StyledFootnotes>
        <p id='footnote-1'>[1] Typical transactions expected to be around $25.</p>
        <p id='footnote-2'>[2] Anonymous between users only. The developer, its partners and affiliates verify each users per the User Agreement.</p>
        <p id='footnote-3'>[3] Dependent on recipient bank’s acceptance of real-time payments.</p>
    </StyledFootnotes>
  )
}

const StyledFootnotes = styled(StyledFooter)`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: #fff;
    font-family: sans-serif;
    font-size: 0.875em;
    font-weight: 200;
    background-color: #222222;
    margin: 0;
    
    width: 100%;
    height: fit-content;
    padding: 2em 0;

    & > p {
        margin: 0;
    }
`

export default Footnotes