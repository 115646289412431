import React from "react";
import {
  StyledDefinition,
  StyledDefinitionFlex,
  StyledDefinitionText,
  StyledDefinitionQuote,
} from "../styles/helpers.styled";

function Definition() {
  return (
    <StyledDefinition>
      <StyledDefinitionFlex className='to-animate' style={{zIndex: "-1"}}>
        <StyledDefinitionQuote>“</StyledDefinitionQuote>
        <div style={{ marginTop: "150px", height: "auto", width: "auto" }}>
          <h2
            style={{
              color: "#000",
              textAlign: "center",
              fontSize: "3em",
              margin: "0",
              // marginTop: "-100px",
            }}
          >
            <strong>/be .stō/ verb /</strong>
          </h2>
          <StyledDefinitionText>
            To present an honor or confer a gift to someone. Thus, for us,
            Bestow is a gesture of acknowledgement for our cashless society.
          </StyledDefinitionText>
        </div>
      </StyledDefinitionFlex>
    </StyledDefinition>
  );
}

export default Definition;

/*
<p style="text-align: center; font-size: 18px;">To present an honor or confer a gift to someone. Thus, for us, Bestow is a gesture of acknowledgement for our cashless society.</p>
*/
