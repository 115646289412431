import styled, { keyframes } from "styled-components";

const slide_up = keyframes`
  0% {
    transform: translateY(250px);
  }

  100% {
    transform: translateY(0);
  }
`;

export const StyledWrapper = styled.div`
  // This container wraps the contains of all the
  // different components and gives them padding
  // in order for all to be aligned

  padding: 0 10%;
  height: 20em;
  width: 100%;
  margin: 0;
  /* background-color: red; */

  @media (max-width: 991px) {
    padding: 0 0.7em;
    margin: 0;
    width: 100%;
  }
`;

export const StyledFlexRow = styled.div`
  // This adds flex behaviour to the elements present inside.

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2em;
  width: auto;
  height: auto;
`;

export const StyledFlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResponsiveMenuStyle = styled(StyledFlexCol)`
  width: 100vw;
  height: 100vh;
  padding: 3em 1.7em;
  background-color: white;
  position: fixed;
  z-index: 50;
`;

export const HamburgerMenuStyles = styled.span`
  display: none;

  @media (max-width: 991px) {
    display: flex;
    align-items: center;
  }
`;

export const StyledNavBar = styled.ul`
  list-style-type: none;
  display: flex;
  gap: 2em;
  letter-spacing: 0.1em;
  margin: 0;

  @media (max-width: 991px) {
    display: none;
  }
`;
export const ResponsiveMenuLinks = styled(StyledNavBar)`
  list-style: none;
  display: flex;
  flex-direction: column;
`;

export const StyledHeader = styled(StyledWrapper)`
  position: sticky;
  z-index: 22;
  height: 4em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: white;

  @media (max-width: 991px) {
    height: 6.5vh;
  }
`;

export const StyledLogo = styled.a`
  color: #34dca2;
  -webkit-transition: color 0.4s;
  -o-transition: color 0.4s;
  transition: color 0.4s;
  text-decoration: none;
  font-size: 0.875em;
  font-weight: 700;
`;

export const StyledLink = styled.a`
  color: #333333;
  text-decoration: none;
  font-size: 1em;
  font-weight: 400;

  &:hover {
    border-bottom: 3px solid #34dca2;
  }
`;

export const StyledPageLink = styled.div`
  & > :first-child {
    color: #333333;
    text-decoration: none;
    font-size: 1em;
    font-weight: 400;

    &:hover {
      border-bottom: 3px solid #34dca2;
    }
  }


`;

export const StyledFooterSectionsHeader = styled.h6`
  color: white;
  font-size: 1.1rem;
  font-weight: 300;
`;

export const ContactContainer = styled.div`
  font-family: sans-serif;
  margin-bottom: 1em;
  font-size: 0.9rem;
  color: white;
  display: flex;
`;

export const StyledPresentation = styled(StyledWrapper)`
  background-color: #222327;
  height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;

  @media (max-width: 991px) {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: 1.4em;
    padding-bottom: 5em;
    height: max-content;
  }
`;

export const StyledPresentationInner = styled(StyledFlexRow)`
  align-items: start;
  justify-content: space-between;
  min-height: fit-content;
  width: 100%;
  height: 50%;

  @media (max-width: 991px) {
    padding-top: 0;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: start;
    justify-content: flex-start;
  }
`;

export const PresentationIframe = styled.div`
  width: 55%;
  height: 25vw;

  @media (max-width: 991px) {
    width: 100%;
    max-height: 60vw;
    height: 50vw;
    display: flex;
    justify-content: center;
    display: flex;
  }
`;

export const StyledPresentationFlex = styled(StyledFlexRow)`
  align-items: start;
  padding-top: 7em;
  width: 100%;
  height: 100%;

  @media (max-width: 991px) {
    flex-direction: column;
    padding-top: 3em;
    height: 100%;
  }
`;

export const StyledIntroVideo = styled.div`
  width: 60%;
  height: 100%;

  @media (max-width: 991px) {
    width: 100%;
    height: 100%;
  }
`;

export const StyledPlatformDownload = styled(StyledFlexRow)`
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: 100%;

  @media (max-width: 991px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const StyledEarlyAccess = styled(StyledFlexRow)`
  flex-direction: column;
  max-width: 50%;
  width: 40%;
  height: 25vw;
  gap: 1em;
  justify-content: center;
  align-items: center;

  & > h2 {
    animation: ${slide_up} 2s ease;
    font-size: 1.5em;
  }

  @media (max-width: 991px) {
    width: 100%;
    max-width: 100%;
    width: 100%;
    height: auto;
    margin-top: 0;
  }
`;

export const StyledDownloadButtons = styled(StyledFlexRow)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0;
  cursor: pointer;
`;

export const StyledComingSoon = styled(StyledFlexRow)`
  width: 100%;
  height: 100vh;
  justify-content: center;
  background-color: #34dca2;
  color: white;
  letter-spacing: 3px;
`;

export const StyledDefinition = styled(StyledWrapper)`
  min-height: 27em;
  height: fit-content;
`;

export const StyledDefinitionFlex = styled(StyledFlexRow)`
  flex-direction: column;
  height: fit-content;
  flex-direction: column;
  gap: 0;
  position: relative;
  min-height: 26em;

  & > * {
    animation: ${slide_up} 2s ease;
  }
`;

export const StyledDefinitionQuote = styled.h6`
  text-align: center;
  font-size: 150px;
  color: #34dca2;
  margin: 0;
  position: absolute;
`;

export const StyledDefinitionText = styled.p`
  text-align: center;
  font-size: 1.15em;
  font-family: Roboto;
  font-weight: normal;
  color: #888888;
  margin: 3em 0;

  @media (max-width: 991px) {
    margin: 1.5em 0;
  }
`;

export const StyledAbout = styled(StyledFlexRow)`
  width: 100%;
  height: 100vh;
  background-color: #222327;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 0 10%;

  & > * {
    animation: ${slide_up} 2s ease;
  }

  @media (max-width: 991px) {
    padding: 5em 5% 10em 5%;
    height: fit-content;
  }
`;

export const StyledAboutSections = styled(StyledFlexRow)`
  align-items: space-between;
  width: 100%;
  gap: 1em;

  @media (max-width: 991px) {
    flex-direction: column;
    height: max-content;
    justify-content: center;
    align-items: center;
  }
`;

export const ComingSoon = styled(StyledFlexRow)`
  text-align: center;
  display: flex;
  gap: 1em;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

export const ComingSoonTitle = styled.h1`
  font-size: 2.7rem;
  margin: 0;

  @media (max-width: 991px) {
    font-size: 3rem;
  }
`;

export const CommingSoonLink = styled.a`
  color: black;
  text-decoration: underline;
`;

export const StyledTextVideo = styled(StyledWrapper)`
  height: max-content; 
  background-color: ${(props) => props.backgroundColor};
  padding-top: 10em;
  padding-bottom: 10em;
  overflow: hidden;

  @media (max-width: 991px) {
    padding: 0;
  }
`;

export const TextVideoSection = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  font-family: "Roboto";
  align-items: center;
  background-color: ${(props) => props.backgroundColor};

  @media (max-width: 991px) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 1em 0;
    flex-direction: column;
    height: auto;
  }
`;

export const IframeContainer = styled.div`
  width: 50%;
  height: 350px;
  background-color: #fff;
  margin-right: ${(props) => (props.videoOrder === 1 ? "6em" : 0)};
  order: ${(props) => props.videoOrder};

  @media (max-width: 991px) {
    width: 100%;
    height: 50vw;
    margin-right: 0;
    order: 1;
  }
`;

export const TextContainer = styled.div`
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  order: ${(props) => props.textOrder};
  margin-right: ${(props) => (props.textOrder === 1 ? "6em" : 0)};

  @media (max-width: 991px) {
    width: 100%;
    margin-right: 0;
    padding: 1em;
    order: 2;
    margin-right: 0;
  }
`;

export const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 20rem;
  background-color: #222222;
  margin-top: 6em;

  @media (max-width: 991px) {
    padding-left: 0.9em;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    font-size: 16px;
  }
`;

export const FooterSection = styled.div`
  margin-right: 14em;
  min-height: 70%;

  @media (max-width: 991px) {
    margin-right: 0;
    height: auto;
  }
`;
export const FooterSocialMedia = styled.a`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: url(${(props) => props.logo});
  background-size: cover;
`;

export const FooterNavigationLink = styled.a`
  font-family: sans-serif;
  margin-bottom: 1em;
  font-size: 0.9rem;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;

  &:hover {
    color: white;
  }
`;

export const StyledFeature = styled(StyledWrapper)`
  height: 250vh;

  @media (max-width: 991px) {
    height: 410vh;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const StyledFeatureHeader = styled(StyledFlexRow)`
  flex-direction: column;
  gap: 1em;
  justify-content: center;
  align-items: center;
  padding-top: 5em;

  @media (max-width: 991px) {
    padding-bottom: 5em;
  }
`;

export const StyledFeatureElements1 = styled(StyledFlexRow)`
  height: 100vh;
  width: 100%;

  @media (max-width: 991px) {
    height: 175vh;
    flex-direction: column-reverse;
    padding-bottom: 5em;
    justify-content: flex-start;
  }
`;

export const StyledFeatureElements2 = styled(StyledFlexRow)`
  height: 100vh;

  width: 100%;
  @media (max-width: 991px) {
    height: 175vh;
    flex-direction: column;
    padding-bottom: 5em;
    padding-top: 0;
    justify-content: flex-start;
  }
`;

export const StyledFeatureElementText = styled(StyledFlexRow)`
  width: 50%;
  flex-direction: column;
  gap: 3em;
  align-items: flex-start;
  padding-top: 5em;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

export const StyledFeatureVide = styled.div`
  width: 50%;
  height: 80%;
  display: flex;
  justify-content: center;

  @media (max-width: 991px) {
    height: 25%;
  }
`;

export const StyledTextPrivacy = styled.p`
  font-family: "Roboto";
  color: #888888;
  font-size: 0.875em;
`

export const StyledFootnote = styled.a`
  color: #24A496;
  text-decoration: none;
  font-size: 0.875em;
`
