import React from "react";
import {
  ComingSoon,
  ComingSoonTitle,
  CommingSoonLink,
  StyledComingSoon,
} from "../../styles/helpers.styled";

function ComingSoonSection() {
  return (
    <StyledComingSoon>
      <ComingSoon className="to-animate">
        <ComingSoonTitle>
          BE$TOW PAYMENTS
        </ComingSoonTitle>
          <ComingSoonTitle><CommingSoonLink href="#">COMING SOON!</CommingSoonLink></ComingSoonTitle>
      </ComingSoon>
    </StyledComingSoon>
  );
}

export default ComingSoonSection;
