import React from "react";
import {
  StyledFooterSectionsHeader,
  FooterSection,
  ContactContainer,
} from "../../styles/helpers.styled";
import { IoCall, IoMail } from "react-icons/io5";

function Contact() {
  return (
    <FooterSection>
      <StyledFooterSectionsHeader>Contact our team</StyledFooterSectionsHeader>
      <ContactComponent icon={<IoCall />} contact={"(917) 251-9583"} />
      <ContactComponent icon={<IoMail />} contact={"info@bestowpayments.com"} />
    </FooterSection>
  );
}

function ContactComponent({ contact, icon }) {
  return (
    <ContactContainer>
      <span style={{ marginRight: ".3em" }}>{icon}</span>
      <span>{contact}</span>
    </ContactContainer>
  );
}

export default Contact;
