import { useLayoutEffect } from "react";

export default function useRedirect() {
  const isMobile = {
    Windows: function () {
      return /IEMobile|Windows Phone|Lumia/i.test(navigator.userAgent);
    },
    Android: function () {
      return /Android/i.test(navigator.userAgent);
    },
    iPhone: function () {
      return /iPhone/i.test(navigator.userAgent);
    },
  };
  const storeLink =
    "https://play.google.com/store/apps/details?id=com.bestowpayments.app&launch=true&lnk=vUcRLz";
  const isBestowMobile =
    window.location.href.indexOf("https://bestowmobile.com?lnk=vUcRLz") !== -1;

  useLayoutEffect(() => {
      if (isMobile.Android()) {
        window.location = storeLink;
      }
  }, []);
}
