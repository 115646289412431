import React, { useState } from "react";
import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";

import {
  StyledHeader,
  StyledFlexRow,
  StyledLogo,
  StyledNavBar,
  ResponsiveMenuStyle,
  ResponsiveMenuLinks,
  HamburgerMenuStyles,
  StyledLink,
  StyledPageLink,
} from "../styles/helpers.styled";

import { Link } from "react-router-dom";

function Header() {
  const [isResponsiveMenuVisible, setResponsiveMenuVisibility] =
    useState(false);

  return (
    <>
      {isResponsiveMenuVisible && (
        <ResponsiveMenu
          setResponsiveMenuVisibility={setResponsiveMenuVisibility}
        />
      )}
      <StyledHeader>
        <StyledFlexRow
          style={{
            padding: "0.5em 0",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <StyledLogo href="https://www.bestowpayments.com/">BE$TOW</StyledLogo>
          <HamburgerMenuStyles
            onClick={() => setResponsiveMenuVisibility(true)}
          >
            <IoMenuOutline />
          </HamburgerMenuStyles>
          <StyledNavBar>
            <li>
              <StyledLink href="#">Home</StyledLink>
            </li>
            <li>
              <StyledLink href="#about">About</StyledLink>
            </li>
            <li>
              <StyledLink href="#features">Features</StyledLink>
            </li>
            <li>
              <StyledLink href="#videos">Videos</StyledLink>
            </li>
            <li>
              <StyledPageLink>
                <Link to={"policy"}>Privacy Policy</Link>
              </StyledPageLink>
            </li>
          </StyledNavBar>
        </StyledFlexRow>
      </StyledHeader>
    </>
  );
}

function ResponsiveMenu({ setResponsiveMenuVisibility }) {
  return (
    <ResponsiveMenuStyle>
      <StyledFlexRow
        style={{
          justifyContent: "flex-end",
          height: "auto",
          marginBottom: "7em",
        }}
      >
        <IoCloseOutline
          size={"2.4rem"}
          color={"#34dca2"}
          onClick={() => setResponsiveMenuVisibility(false)}
        />
      </StyledFlexRow>

      <ResponsiveMenuLinks>
        <li>
          <StyledLink
            href="#"
            onClick={() => setResponsiveMenuVisibility(false)}
          >
            Home
          </StyledLink>
        </li>
        <li>
          <StyledLink
            href="#about"
            onClick={() => setResponsiveMenuVisibility(false)}
          >
            About
          </StyledLink>
        </li>
        <li>
          <StyledLink
            href="#features"
            onClick={() => setResponsiveMenuVisibility(false)}
          >
            Features
          </StyledLink>
        </li>
        <li>
          <StyledLink
            href="#videos"
            onClick={() => setResponsiveMenuVisibility(false)}
          >
            Videos
          </StyledLink>
        </li>
        <li>
          <StyledPageLink onClick={() => setResponsiveMenuVisibility(false)}>
            <Link to={"policy"}>Privacy Policy</Link>
          </StyledPageLink>
        </li>
      </ResponsiveMenuLinks>
    </ResponsiveMenuStyle>
  );
}

export default Header;
