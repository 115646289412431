import React from "react";
import Footer from "./Footer/Footer";
import { StyledTextPrivacy, StyledWrapper } from "../styles/helpers.styled";

import { IoMenuOutline, IoCloseOutline } from "react-icons/io5";
import {
  StyledHeader,
  StyledFlexRow,
  StyledLogo,
  StyledNavBar,
  ResponsiveMenuStyle,
  ResponsiveMenuLinks,
  HamburgerMenuStyles,
  StyledLink,
  StyledPageLink,
} from "../styles/helpers.styled";

import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <>
      <Header />
      <StyledWrapper style={{ height: "max-content" }}>
        <Main />
      </StyledWrapper>
      <Footer />
    </>
  );
}

function Main() {
  return (
    <>
      <h1>PRIVACY POLICY</h1>
      <h2>I. Introduction</h2>
      <StyledTextPrivacy>
        Bestow, a Horus Foster, Inc. service, knows that you care about how your
        personal information is used and shared, and we take your privacy
        seriously. Our primary goal is to provide you with exceptional service,
        and we understand that you may have questions or concerns regarding your
        personal information and how it will be used. To make this privacy
        policy easy to find, we make it available from the home page of the
        Bestow website at bestowpayments.com (the “Website”) and through our
        mobile applications. You may also email us at privacy@bestowpayments.com with
        any privacy related questions you have.
      </StyledTextPrivacy>
      <h2>II. APPLICABILITY OF PRIVACY POLICY</h2>
      <StyledTextPrivacy>
        This privacy policy applies to all information we collect through our
        Services from current and former Bestow users, including you. “Services”
        means any products, services, content, features, technologies, or
        functions, and all related websites, applications, and services offered
        to you by Horus Foster, Inc. in connection with a Bestow account. When
        you are no longer our customer, we continue to share your information as
        described in this policy. This policy only applies to the Services and
        does not apply to the practices of any other Horus Foster service.
        Throughout this policy, we use the term “personal information” to
        describe information that can be associated with a specific person and
        can be used to identify that person. We do not consider personal
        information to include information that has been aggregated and/or
        anonymized so that it does not identify a specific user.
      </StyledTextPrivacy>
      <h2>III. THE INFORMATION WE COLLECT</h2>
      <StyledTextPrivacy>
        When you use our mobile applications or other Services, we collect your
        IP address, and Geolocation Information (defined below). If you do not
        agree to our collection of this information, you may not be able to use
        our Service. If you open a Bestow account, we collect the following
        information from you:
      </StyledTextPrivacy>
      <ul
        style={{
          color: "#888888",
          fontSize: "0.875em",
          fontFamily: "Roboto",
          lineHeight: "2em",
        }}
      >
        <li>
          Account Information – text-enabled cellular/wireless telephone number,
          machine or mobile device ID, and other similar information.
        </li>
        <li>Identification Information – your name and/or email address</li>
        <li>
          Device Information – information about you: (a) from your mobile
          device or computer such as your device type, machine or mobile device
          identification number, Geolocation Information, time zone, language
          setting, browser type, and IP address, and (b) from third parties for
          purposes of transaction processing, identity verification, fraud
          detection or prevention and other similar purposes. For fraud
          prevention purposes, we also may link your machine ID with the
          machines of others who use your same payment cards.
        </li>
        <li>
          Geolocation Information – information that identifies with reasonable
          specificity your location by using, for instance, longitude and
          latitude coordinates obtained through GPS, Wi-Fi, or cell site
          triangulation. We may collect this data for fraud and risk purposes.
          In addition, some of our Services may ask you for permission to share
          your current location within your device settings to enhance our
          Services. If you do not agree to our collection of Geolocation
          Information, our Services may not function properly when you try to
          use them. For information about your ability to restrict the
          collection and use of Geolocation Information to enhance our Services,
          please refer to the settings available on your device.
        </li>
        <li>
          Financial Information – bank account online login information, bank
          account, and routing numbers, and debit cards linked to your Bestow
          account. Bestow does not share financial information with third-party
          social networking services such as those listed in the prior bullet.
        </li>
      </ul>
      <StyledTextPrivacy>
        We are committed to providing a safe, secure, and all-around great
        service. Therefore, before permitting you to use the Services, we may
        require additional information from you we can use to verify your
        identity, address, or other information or to manage risk and compliance
        throughout our relationship. We may also obtain information about you
        from third parties such as identity verification, fraud prevention, and
        similar services.
      </StyledTextPrivacy>
      <StyledTextPrivacy>
        When you are using the Services, we collect information about your
        account transactions and we may collect Geolocation Information and/or
        information about your computer or another access device for fraud
        prevention and other similar purposes.
      </StyledTextPrivacy>
      <StyledTextPrivacy>
        Finally, we may collect additional information from or about you in
        other ways not specifically described here. For example, we may collect
        information related to your contact with our customer support team or
        store results when you respond to a survey.
      </StyledTextPrivacy>
      <h2>IV. INFORMATION FROM CHILDREN</h2>
      <StyledTextPrivacy>
        The Services are not directed to children under the age of 13. If we
        obtain actual knowledge that we have collected personal information from
        a child under the age of 13, we will promptly delete it, unless we are
        legally obligated to retain such data. Contact us if you believe that we
        have mistakenly or unintentionally collected information from a child
        under the age of 13.
      </StyledTextPrivacy>
      <h2>V. HOW WE USE COOKIES</h2>
      <StyledTextPrivacy>
        When you visit or use our Services, or visit a third-party website for
        which we provide online services, we and certain business partners and
        vendors may use cookies and other tracking technologies (collectively,
        “Cookies”). We use cookies to recognize you as a customer; customize
        Services, other content, and advertising; measure the effectiveness of
        promotions; perform a wide range of analytics; mitigate risk and prevent
        potential fraud, and promote trust and safety across our Services.
      </StyledTextPrivacy>
      <StyledTextPrivacy>
        Certain Services are only available through the use of Cookies, so if
        you choose to disable or decline Cookies, your use of certain Services
        may be limited or not possible.
      </StyledTextPrivacy>
      <StyledTextPrivacy>
        Do Not Track: Do Not Track (“DNT”) is an optional browser setting that
        allows you to express your preferences regarding tracking by advertisers
        and other third parties. We do not respond to DNT signals.
      </StyledTextPrivacy>
    </>
  );
}

function Header() {
  const [isResponsiveMenuVisible, setResponsiveMenuVisibility] =
    React.useState(false);

  return (
    <>
      {isResponsiveMenuVisible && (
        <ResponsiveMenu
          setResponsiveMenuVisibility={setResponsiveMenuVisibility}
        />
      )}
      <StyledHeader>
        <StyledFlexRow
          style={{
            padding: "0.5em 0",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <StyledLogo href={window.location.origin}>BE$TOW</StyledLogo>
          <HamburgerMenuStyles
            onClick={() => setResponsiveMenuVisibility(true)}
          >
            <IoMenuOutline />
          </HamburgerMenuStyles>
          <StyledNavBar>
            <li>
              <StyledPageLink>
                <Link to={"/"}>Home</Link>
              </StyledPageLink>
            </li>
            <li>
              <StyledLink href={window.location.origin + "/#about"}>
                About
              </StyledLink>
              {/* <StyledPageLink>
                <Link to={{ pathname: "/", hash: "#about" }}>About</Link>
              </StyledPageLink> */}
            </li>
            <li>
            <StyledLink href={window.location.origin + "/#features"}>
                Features
              </StyledLink>
            </li>
            <li>
            <StyledLink href={window.location.origin + "/#videos"}>
                Videos
              </StyledLink>
            </li>
            <li>
              <StyledLink href="#">Policy Privacy</StyledLink>
            </li>
          </StyledNavBar>
        </StyledFlexRow>
      </StyledHeader>
    </>
  );
}

function ResponsiveMenu({ setResponsiveMenuVisibility }) {
  return (
    <ResponsiveMenuStyle>
      <StyledFlexRow
        style={{
          justifyContent: "flex-end",
          height: "auto",
          marginBottom: "7em",
        }}
      >
        <IoCloseOutline
          size={"2.4rem"}
          color={"#34dca2"}
          onClick={() => setResponsiveMenuVisibility(false)}
        />
      </StyledFlexRow>

      <ResponsiveMenuLinks>
      <li>
              <StyledPageLink>
                <Link to={"/"}>Home</Link>
              </StyledPageLink>
            </li>
            <li>
              <StyledLink href={window.location.origin + "/#about"}>
                About
              </StyledLink>
              {/* <StyledPageLink>
                <Link to={{ pathname: "/", hash: "#about" }}>About</Link>
              </StyledPageLink> */}
            </li>
            <li>
            <StyledLink href={window.location.origin + "/#features"}>
                Features
              </StyledLink>
            </li>
            <li>
            <StyledLink href={window.location.origin + "/#videos"}>
                Videos
              </StyledLink>
            </li>
            <li>
              <StyledLink href="#">Policy Privacy</StyledLink>
            </li>
      </ResponsiveMenuLinks>
    </ResponsiveMenuStyle>
  );
}

export default PrivacyPolicy;
