import React from "react";
import {
  StyledFeatureHeader,
  StyledFlexRow,
  StyledFeature,
  StyledFeatureElements1,
  StyledFeatureElements2,
  StyledFeatureElementText,
  StyledFeatureVide,
  StyledFootnote,
} from "../styles/helpers.styled";
import { featureData } from "../../data/data";
import bestow1 from "../../assets/images/bestow1.gif";
import bestow2 from "../../assets/images/bestow2.gif";

function Features() {
  return (
    <StyledFeature id="features">
      <StyledFeatureHeader>
        <p style={{ fontSize: "0.875em", color: "#34dca2" }}>THE BENEFITS</p>
        <h2 style={{ fontSize: "3em", color: "#333", margin: "0" }}>
          WHY BE$TOW
        </h2>
        <p
          style={{
            fontFamily: "Roboto",
            color: "#888",
            fontSize: "1.2em",
            margin: "0",
            textAlign: "center",
          }}
        >
          With Be$tow you get all the benefits of using cash in an easy-to-use,
          contactless mobile application!
        </p>
      </StyledFeatureHeader>

      {/* The first 3 features  */}
      <StyledFeatureElements1>
        <StyledFeatureElementText>
          <FeatureElement data={featureData[0][0]} />
          <FeatureElement data={featureData[0][1]} />
          <FeatureElement data={featureData[0][2]} />
        </StyledFeatureElementText>
        <StyledFeatureVide
          className="to-animate"
        >
          <img width="auto" height="100%" alt="bestow1" src={bestow1} />
        </StyledFeatureVide>
      </StyledFeatureElements1>

      {/* The last 3 features  */}
      <StyledFeatureElements2>
        <StyledFeatureVide
          className="to-animate"
        >
          <img width="auto" height="100%" alt="bestow2" src={bestow2} />
        </StyledFeatureVide>
        <StyledFeatureElementText to-animate>
          <FeatureElementFoo data={featureData[1][0]} />
          <FeatureElement data={featureData[1][1]} />
          <FeatureElement data={featureData[1][2]} />
        </StyledFeatureElementText>
      </StyledFeatureElements2>
    </StyledFeature>
  );
}

function FeatureElement({ data }) {
  return (
    <StyledFlexRow style={{ alignItems: "start" }}>
      <div className="aio-icon-left">
        <div className="ult-just-icon-wrapper  ">
          <div class="align-icon" style={{ textAlign: "center" }}>
            <div
              className="aio-icon none animated fadeInUp delay-03 to-animate"
              dataAnimation="fadeInUp"
              dataAnimation-delay="03"
              style={{
                color: "#34dca2",
                fontSize: "48px",
                display: "inline-block",
              }}
            >
              <i className="icomoon-uniF245"></i>
            </div>
          </div>
        </div>
      </div>
      <StyledFlexRow
        style={{
          flexDirection: "column",
          gap: "1.5em",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <h2 style={{ margin: "0", fontSize: "2em" }}>{data.title}</h2>
        <p style={{ margin: "0", color: "#888", fontFamily: "Roboto" }}>
          {data.text}
        </p>
      </StyledFlexRow>
    </StyledFlexRow>
  );
}

function FeatureElementFoo({ data }) {
  return (
    <StyledFlexRow style={{ alignItems: "start" }}>
      <div className="aio-icon-left">
        <div className="ult-just-icon-wrapper  ">
          <div class="align-icon" style={{ textAlign: "center" }}>
            <div
              className="aio-icon none animated fadeInUp delay-03 to-animate"
              dataAnimation="fadeInUp"
              dataAnimation-delay="03"
              style={{
                color: "#34dca2",
                fontSize: "48px",
                display: "inline-block",
              }}
            >
              <i className="icomoon-uniF245"></i>
            </div>
          </div>
        </div>
      </div>
      <StyledFlexRow
        style={{
          flexDirection: "column",
          gap: "1.5em",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
      >
        <h2 style={{ margin: "0", fontSize: "2em" }}>{data.title}</h2>
        <p style={{ margin: "0", color: "#888", fontFamily: "Roboto" }}>
          {data.text}<StyledFootnote name='footnote-2' href="#footnote-2">[3]</StyledFootnote>
        </p>
      </StyledFlexRow>
    </StyledFlexRow>
  );
}

export default Features;
