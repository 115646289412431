import React from "react";
import {
  StyledFooterSectionsHeader,
  FooterSection,
  StyledFlexRow,
  FooterSocialMedia,
} from "../../styles/helpers.styled";

import igLogo from "../../../assets/images/ig-logo.png";
import linkedinLogo from "../../../assets/images/linkedin.png";

function SocialMedias() {
  return (
    <FooterSection>
      <StyledFooterSectionsHeader>FOLLOW BE$TOW</StyledFooterSectionsHeader>
      <StyledFlexRow>
        <FooterSocialMedia
          logo={igLogo}
          href="https://instagram.com/bestowapp?utm_medium=copy_link"
        ></FooterSocialMedia>
        <FooterSocialMedia
          logo={linkedinLogo}
          href="https://www.linkedin.com/company/bestow-app"
        ></FooterSocialMedia>
      </StyledFlexRow>
    </FooterSection>
  );
}

export default SocialMedias;
