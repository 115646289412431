export function Svg1() {
  return (
    <div className="aio-icon-top">
      <div className="ult-just-icon-wrapper">
        <div className="align-icon" style={{ textAlign: "center" }}>
          <div
            className="aio-icon none animated fadeInUp delay-03"
            dataAnimation="fadeInUp"
            dataAnimation-delay="03"
            style={{
              color: "#34dca2",
              fontSize: "48px",
              display: "inline-block",
            }}
          >
            <i class="icomoon-uniF1D4"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Svg2() {
  return (
    <div className="aio-icon-top">
      <div className="ult-just-icon-wrapper  ">
        <div class="align-icon" style={{ textAlign: "center" }}>
          <div
            className="aio-icon none animated fadeInUp delay-03"
            dataAnimation="fadeInUp"
            dataAnimation-delay="03"
            style={{
              color: "#34dca2",
              fontSize: "48px",
              display: "inline-block",
            }}
          >
            <i className="icomoon-uniF1A6"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Svg3() {
  return (
    <div className="aio-icon-top">
      <div className="ult-just-icon-wrapper">
        <div className="align-icon" style={{ textAlign: "center" }}>
          <div
            class="aio-icon none animated fadeInUp delay-03"
            dataAnimation="fadeInUp"
            dataAnimation-delay="03"
            style={{
              color: "#34dca2",
              fontSize: "48px",
              display: "inline-block",
            }}
          >
            <i className="icomoon-uniF14C"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Svg4() {
  return (
    <div className="aio-icon-top">
      <div className="ult-just-icon-wrapper  ">
        <div className="align-icon" style={{textAlign:"center"}}>
          <div
            className="aio-icon none animated fadeInUp delay-03"
            dataAnimation="fadeInUp"
            dataAnimation-delay="03"
            style={{color:"#34dca2", fontSize:"48px", display:"inline-block"}}
          >
            <i className="icomoon-uniF22B"></i>
          </div>
        </div>
      </div>
    </div>
  );
}

export const data = [
  {
    subTitle: "WANT TO SPREAD THE LOVE?",
    title: "BE$TOW BECAUSE YOU CARE",
    video:
      "https://www.youtube.com/embed/vUDJMMcOLyQ?enablejsapi=1&autoplay=0&cc_load_policy=0&cc_lang_pref=&iv_load_policy=1&loop=0&modestbranding=1&rel=0&fs=1&playsinline=1&autohide=2&theme=dark&color=red&controls=1&",
    text: "Support causes that center around the things you care about the most. Wherever you may be, regardless if you have cash, Bestow enables you to contribute to your community and give where it has the most impact. Moving with ease, through each possible scenario.",
    videoOrder: 1,
    textOrder: 2,
    backgroundColor: "#f3f4f6",
  },
  {
    subTitle: "NO CASH FOR A TIP?",
    title: "BE$TOW TO SHOW APPRECIATION",
    video:
      "https://www.youtube.com/embed/SdqUkE0aze4?enablejsapi=1&autoplay=0&cc_load_policy=0&cc_lang_pref=&iv_load_policy=1&loop=0&modestbranding=1&rel=0&fs=1&playsinline=1&autohide=2&theme=dark&color=red&controls=1&",
    text: "Bestow means forgetting cash doesn’t mean forgetting service or having folks be left behind uncompensated; even when they are not there. Bestow truly democratizes financial technology to include all.",
    videoOrder: 2,
    textOrder: 1,
    backgroundColor: "#fff",
  },
  {
    subTitle: "IN A HURRY?",
    title: "BE$TOW WHEN YOU’RE ON-THE-GO",
    video:
      "https://www.youtube.com/embed/rHEMPLZ1__Q?enablejsapi=1&autoplay=0&cc_load_policy=0&cc_lang_pref=&iv_load_policy=1&loop=0&modestbranding=1&rel=0&fs=1&playsinline=1&autohide=2&theme=dark&color=red&controls=1&",
    text: "Bestow enables quick and easy transactions for those who cannot afford to stop. For moments when an ATM makes no sense. Valet is our easy example, for when sharing your username or number proves to be a detriment to safety, security or privacy.",
    videoOrder: 1,
    textOrder: 2,
    backgroundColor: "#f3f4f6",
  },
];

export const aboutData = [
  {
    logo: "",
    title: "DIGITAL CASH",
    text: "A digital solution for tips, donations and payments that is as anonymous as,  and effortless as paying in cash.",
  },
  {
    logo: "",
    title: "EASY TO GIVE",
    text: "Acknowledge those in need, use Bestow to send donations to individuals and charitable organizations.",
  },
  {
    logo: "",
    title: "EASY TO TIP",
    text: "Finally an easier way to tip- acknowledge Bellhops, Valets, Barbers, Stylists, etc. with a gratuity.",
  },
  {
    logo: "",
    title: "EASY TO USE",
    text: "Just tap your mobile device or smart watch to another device and Be$tow cash via the Widget Screen.",
  },
];

export const featureData = [
  [
    {
      title: "ZERO FRICTION",
      text: "Be$tow’s technology emulates all of the frictionless aspects of cash- simplicity, convenience, and anonymity.",
    },
    {
      title: "PRIVACY FIRST",
      text: "Pay anyone in-person without ever having to exchange any personal details like your name, email, or phone number!",
    },
    {
      title: "UBIQUITOUS",
      text: "Most people today have a smartphone or a smart watch, a compatible device is the only thing that you need to Be$tow.",
    },
  ],
  [
    {
      title: "NO APP NEEDED",
      text: "Don’t have the Bestow app? No problem! Receive your payments first, and install the app later.",
    },
    {
      title: "INSTANT TRANSFER",
      text: "Stop waiting around for your money! Payments are transferred digitally, and available for immediate use.",
    },
    {
      title: "TAP & GO",
      text: "Just tap another device (iOS or Android) to send a payment. Be$tow’s NFC technology makes it that easy!",
    },
  ],
];
